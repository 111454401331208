import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Field} from '../models/field';
import {environment} from '../../../environments/environment';
import {LoadingService} from './loading.service';
import {HttpClient, HttpParams} from '@angular/common/http';
import {map} from 'rxjs/operators';

@Injectable()
export class FieldsService {

  constructor(private http: HttpClient, private loadingService: LoadingService) {
  }

  getAllFields(zoneId?: number): Observable<Field[]> {
    let params: HttpParams = new HttpParams();
    params = params.set('all', '1');
    return this.loadFields(params, zoneId);
  }

  getFields(zoneId?: number): Observable<Field[]> {
    return this.loadFields(null, zoneId);
  }

  private loadFields(params?: HttpParams, zoneId?: number): Observable<Field[]> {
    return this.http.get<any>(`${environment.apiUrl}/field`, {params})
      .pipe(map((response) => {
        const fieldsFromJSON = response;
        const fields: Field[] = [];
        fieldsFromJSON.forEach((field) => {
          if ((zoneId && +field.field_zone_id === zoneId) || !zoneId) {
            fields.push(Field.fromJson(field));
          }
        });
        return fields.sort((a, b): number => {
          if (a.name.toLowerCase() > b.name.toLowerCase()) {
            return 1;
          }
          if (a.name.toLowerCase() < b.name.toLowerCase()) {
            return -1;
          }
          return 0;
        });
      }));
  }

  getFieldById(fieldId: number): Observable<Field> {
    return this.http.get<any>(`${environment.apiUrl}/field/${fieldId}`)
      .pipe(map((response) => {
        return Field.fromJson(response);
      }));
  }

  createField(field: Field): Observable<boolean> {
    return this.http.post<any>(`${environment.apiUrl}/field`, field.toJson())
      .pipe(map(() => {
        return true;
      }));
  }

  updateField(field: Field): Observable<boolean> {
    return this.http.put<any>(`${environment.apiUrl}/field`, field.toJson())
      .pipe(map(() => {
        return true;
      }));
  }

  deleteField(fieldId: number): Observable<boolean> {
    return this.http.delete(`${environment.apiUrl}/field/${fieldId}`)
      .pipe(map(() => {
        return true;
      }));
  }
}
