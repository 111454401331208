import {environment} from '../../../environments/environment';

export class Treatment {
  id: number;
  description: string;
  info: string;
  plotId: number;
  treatmentType: TreatmentType;
  evaluationType: EvaluationType;
  fields: TreatmentField[];
  images: TreatmentImage[];

  constructor() {
    this.fields = [];
  }

  static fromJson(json): Treatment {
    const treatment = Object.create(Treatment.prototype);
    treatment.id = parseInt(json.tr_id);
    treatment.description = json.tr_description;
    treatment.info = json.tr_info;
    treatment.treatmentType = TreatmentType.fromJson(json.tr_tt_id);
    treatment.plotId = parseInt(json.tr_plot_id);
    return treatment;
  }

  toJson() {
    const treatment = {
      tr_description: this.description,
      tr_info: this.info,
      tr_plot_id: this.plotId,
      tr_id: this.id
    };

    if (this.evaluationType) {
      treatment['tr_evaluation_type'] = this.evaluationType.id;
    }

    if (this.treatmentType) {
      treatment['tr_tt_id'] = this.treatmentType.id;
    }

    return treatment;
  }
}

export class TreatmentImage {
  id: string;
  timestamp: Date;
  mimeType: string;
  url: string;
  fileErased: boolean;

  static fromJson(json): TreatmentImage {
    const treatmentImage = Object.create(TreatmentImage.prototype);
    treatmentImage.id = json.file_id;
    treatmentImage.timestamp = new Date(json.file_timestamp);
    treatmentImage.mimeType = json.file_type;
    treatmentImage.url = environment.assetsUrl + '/' + json.file_url;
    treatmentImage.fileErased = json.file_erased;
    return treatmentImage;
  }

  toJson() {
    const treatmentImage = {
      file_id: this.id,
      file_timestamp: this.timestamp,
      file_type: this.mimeType,
      file_url: this.url,
      file_erased: this.fileErased
    };
    return treatmentImage;
  }
}

export class TreatmentField {
  id: number;
  pass: number;
  stage: number;
  treatmentId: number;
  value: number;
  treatmentTypeField: TreatmentTypeField;

  static fromJson(json): TreatmentField {
    const treatmentField = Object.create(TreatmentField.prototype);
    treatmentField.id = parseInt(json.tf_id);
    treatmentField.pass = parseInt(json.tf_pass);
    treatmentField.stage = parseInt(json.tf_stage);
    treatmentField.treatmentId = parseInt(json.tf_tr_id);
    treatmentField.value = parseInt(json.tf_value);
    return treatmentField;
  }

  toJson() {
    const treatmentField = {
      tf_value: this.value,
      tf_tr_id: this.treatmentId,
      tf_id: this.id,
      tf_pass: this.pass,
      tf_stage: this.stage
    };

    if (this.treatmentTypeField) {
      treatmentField['tf_ttf_id'] = this.treatmentTypeField.id;
    }

    return treatmentField;
  }
}

export class EvaluationType {
  id: number;
  name: string;

  static fromJson(json): EvaluationType {
    const evaluationType = Object.create(EvaluationType.prototype);
    evaluationType.id = parseInt(json.et_id);
    evaluationType.name = json.et_name;
    return evaluationType;
  }

  toJson() {
    return {
      et_id: this.id,
      et_name: this.name
    };
  }
}

export class TreatmentType {
  id: number;
  name: string;

  static fromJson(json): TreatmentType {
    const treatmentType = Object.create(TreatmentType.prototype);
    treatmentType.id = parseInt(json.tt_id);
    treatmentType.name = json.tt_name;
    return treatmentType;
  }

  toJson() {
    return {
      tt_id: this.id,
      tt_name: this.name
    };
  }
}

export class TreatmentStep {
  id: number;
  treatmentTypeId: number;
  step: number;
  name: string;
  maxPasses: number;

  static fromJson(json): TreatmentStep {
    const treatmentStep = Object.create(TreatmentStep.prototype);
    treatmentStep.id = parseInt(json.ts_id);
    treatmentStep.treatmentTypeId = parseInt(json.ts_tt_id);
    treatmentStep.name = json.ts_name;
    treatmentStep.step = parseInt(json.ts_step);
    treatmentStep.maxPasses = parseInt(json.ts_max_passes);
    return treatmentStep;
  }

  toJson() {
    return {
      ts_id: this.id,
      ts_tt_id: this.treatmentTypeId,
      ts_step: this.step,
      ts_nam: this.name,
      ts_max_passes: this.maxPasses
    };
  }
}

export class TreatmentTypeField {
  id: number;
  name: string;
  treatmentTypeId: number;
  step: number;

  static fromJson(json): TreatmentTypeField {
    const treatmentTypeField = Object.create(TreatmentTypeField.prototype);
    treatmentTypeField.id = parseInt(json.ttf_id);
    treatmentTypeField.name = json.ttf_name;
    treatmentTypeField.treatmentTypeId = parseInt(json.ttf_tt_id);
    treatmentTypeField.step = parseInt(json.ttf_step);
    return treatmentTypeField;
  }

  toJson() {
    return {
      ttf_id: this.id,
      ttf_name: this.name,
      ttf_tt_id: this.treatmentTypeId,
      ttf_step: this.step
    };
  }
}

export class PhenologyState {
  id: number;
  name: string;

  static fromJson(json): PhenologyState {
    const phenologyState = Object.create(PhenologyState.prototype);
    phenologyState.id = parseInt(json.ps_id);
    phenologyState.name = json.ps_name;
    return phenologyState;
  }

  toJson() {
    return {
      ps_id: this.id,
      ps_name: this.name,
    };
  }
}

export class IsolationType {
  id: number;
  name: string;

  static fromJson(json): IsolationType {
    const isolationType = Object.create(IsolationType.prototype);
    isolationType.id = parseInt(json.it_id);
    isolationType.name = json.it_name;
    return isolationType;
  }

  toJson() {
    return {
      it_id: this.id,
      it_name: this.name,
    };
  }
}
