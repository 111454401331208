import { Pipe, PipeTransform } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Pipe({
  name: 'boolean'
})
export class BooleanPipe implements PipeTransform {

  yesLabel: string;
  noLabel: string;

  constructor(private translateService: TranslateService) {
    this.translateService.get('FORM.CHECKBOX.YES').subscribe(value => {
      this.yesLabel = value;
    });
    this.translateService.get('FORM.CHECKBOX.NO').subscribe(value => {
      this.noLabel = value;
    });

  }
  transform(value: any, args?: any): any {
    return value ? this.yesLabel : this.noLabel;
  }

}
