import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {Observable, Subject} from 'rxjs';
import {User} from '../models/user';
import {Role} from '../models/role';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {JwtHelperService} from '@auth0/angular-jwt';

@Injectable()
export class AuthService {

  redirectUrl = '';
  private _treatmentTypeSelected: Subject<number> = new Subject<number>();
  public treatmentTypeSelected = this._treatmentTypeSelected.asObservable();

  constructor(private http: HttpClient) {
  }

  login(credentials): Observable<boolean> {
    const url = environment.apiUrl + '/login';
    return this.http.post(url, credentials)
      .pipe(map((response) => {
        const body = response;
        if (typeof body === 'object' && response['user_token']) {
          localStorage.setItem('token', body['user_token']);
          localStorage.setItem('evaluation_type', JSON.stringify(body['evaluation_type']));
          localStorage.setItem('treatment_type', JSON.stringify(body['treatment_type']));
          localStorage.setItem('treatment_step', JSON.stringify(body['treatment_step']));
          localStorage.setItem('treatment_type_field', JSON.stringify(body['treatment_type_field']));
          localStorage.setItem('treatment_type_field_evaluation', JSON.stringify(body['treatment_type_field_evaluation']));
          localStorage.setItem('phenology_state', JSON.stringify(body['phenology_state']));
          localStorage.setItem('isolation_type', JSON.stringify(body['isolation_type']));
          localStorage.setItem('treatment_type_selected', (body['treatment_type'][0].tt_id));
          return true;
        }
        return false;
      }));
  }

  logout(): Observable<boolean> {
    return this.http.post(environment.apiUrl + '/logout', {})
      .pipe(map((response) => {
        this.clearLocalStorage();
        return true;
      }));
  }

  isLoggedIn(): boolean {
    return !!localStorage.getItem('token');
  }

  getUserToken(): string {
    return localStorage.getItem('token');
  }

  getUserLogged(): User {
    const jwtHelper = new JwtHelperService();
    const token = localStorage.getItem('token');
    const session = jwtHelper.decodeToken(token);
    const user = new User();
    user.id = session.user.user_id;
    user.firstName = session.user.user_name || '';
    user.lastName = session.user.user_lastname || '';
    user.role = Role.fromId(session.user.user_role_id);
    user.email = session.user.user_email;
    user.userTreatmentTypeAccess = session.user.user_treatment_type_access;
    return user;
  }

  isAdminUser(): boolean {
    const userLogged = this.getUserLogged();
    return +(userLogged.role.id) === 1;
  }

  isCoordinatorUser(): boolean {
    const userLogged = this.getUserLogged();
    return +(userLogged.role.id) === 2;
  }

  isAuditorUser(): boolean {
    const userLogged = this.getUserLogged();
    return +(userLogged.role.id) === 3;
  }

  getTreatmentTypeAccess(): number {
    const userLogged = this.getUserLogged();
    return +(userLogged.userTreatmentTypeAccess);
  }

  getTreatmentTypeSelectedValue() {
    return +(localStorage.getItem('treatment_type_selected'));
  }

  setTreatmentTypeSelected(treatmentTypeId: number) {
    localStorage.setItem('treatment_type_selected', treatmentTypeId.toString());
    this._treatmentTypeSelected.next(treatmentTypeId);
  }

  clearLocalStorage() {
    localStorage.removeItem('treatment_type_selected');
    localStorage.removeItem('token');
    localStorage.removeItem('evaluation_type');
    localStorage.removeItem('treatment_type');
    localStorage.removeItem('treatment_step');
    localStorage.removeItem('treatment_type_field');
    localStorage.removeItem('treatment_type_field_evaluation');
    localStorage.removeItem('phenology_state');
    localStorage.removeItem('isolation_type');
  }
}
