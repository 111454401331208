import {NgModule} from '@angular/core';
import {MatDialogModule} from '@angular/material';

@NgModule({
  imports: [
    MatDialogModule
  ],
  exports: [
    MatDialogModule
  ]
})
export class ConfirmModule {
}
