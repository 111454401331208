import { Injectable } from '@angular/core';

@Injectable()
export class BreadcrumbService {

  private routeFriendlyNames: any = {};
  private hideRoutes: string[] = [];

  addFriendlyNameForRoute(route: string, name: string): void {
    this.routeFriendlyNames[route] = name;
  }

  getFriendlyNameForRoute(route: string): string {
    let val = this.routeFriendlyNames[route];
    if (!val) {
      val = route.substr(1, route.length);
    }
    return val;
  }

  hideRoute(route: string): void {
    if (this.hideRoutes.indexOf(route) === -1) {
      this.hideRoutes.push(route);
    }
  }

  isRouteHidden(route: string): boolean {
    return this.hideRoutes.indexOf(route) > -1;
  }

}
