import {Role} from './role';

export class UserZone {
  id: number;
  userId: number;
  zoneId: number;

  static fromJson(json): UserZone {
    const user = Object.create(UserZone.prototype);
    user.id = +(json.uz_id);
    user.userId = +(json.uz_user_id);
    user.zoneId = +(json.uz_zone_id);
    return user;
  }

  toJson() {
    const userZone = {
      uz_user_id: this.userId,
      uz_zone_id: this.zoneId
    };

    if (this.id) {
      userZone['uz_id'] = this.id;
    }

    return userZone;
  }
}

export class User {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  role: Role;
  image: string;
  username: string;
  sendAlerts: boolean;
  userTreatmentTypeAccess: number;

  static fromJson(json): User {
    const user = Object.create(User.prototype);
    user.id = json.user_id;
    user.firstName = json.user_name;
    user.lastName = json.user_lastname;
    user.email = json.user_email;
    user.password = json.user_password;
    user.role = Role.fromId(json.user_role_id);
    user.username = json.user_username;
    user.sendAlerts = +json.user_send_alerts === 1;
    user.userTreatmentTypeAccess = +json.user_treatment_type_access;
    return user;
  }

  toJson() {
    const user = {
      user_id: this.id,
      user_name: this.firstName,
      user_lastname: this.lastName,
      user_email: this.email,
      user_username: this.username,
      user_role_id: this.role.id,
      user_send_alerts: this.sendAlerts ? 1 : 0,
      user_treatment_type_access: +this.userTreatmentTypeAccess
    };

    if (this.role) {
      user.user_role_id = this.role.id;
    }

    if (this.password) {
      user['user_password'] = this.password;
    }

    return user;
  }
}
