import {Injectable} from '@angular/core';
import {
  EvaluationType,
  IsolationType,
  PhenologyState,
  TreatmentField,
  TreatmentImage,
  TreatmentStep,
  TreatmentType,
  TreatmentTypeField
} from '../models/treatment';
import {environment} from '../../../environments/environment';
import * as _ from 'lodash';
import {TreatmentList, TreatmentListFied, TreatmentSoyList} from '../models/treatment-list';
import {Plot} from '../models/plot';
import {Field} from '../models/field';
import {User} from '../models/user';
import {HttpClient, HttpParams} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {AuthService} from './auth.service';

@Injectable()
export class TreatmentsService {

  constructor(private http: HttpClient, private authService: AuthService) {
  }

  getCornTreatments(params: HttpParams, plots: Plot[], fields: Field[]): Observable<TreatmentList[]> {
    return this.http.get<any>(`${environment.apiUrl}/treatment`, {params})
      .pipe(map((response) => {
        const treatments: TreatmentList[] = [];
        const responseFromJSON = response;
        if (responseFromJSON && responseFromJSON.treatment) {
          responseFromJSON.treatment.forEach((treatment) => {
            const treatmentListItem = this.loadCornTreatment(treatment, responseFromJSON.treatment_field, plots, fields, responseFromJSON.file, responseFromJSON.treatment_file);
            if (treatmentListItem) {
              treatments.push(treatmentListItem);
            }
          });
        }
        return treatments;
      }));
  }

  getSoyTreatments(params: HttpParams, plots: Plot[], fields: Field[]): Observable<TreatmentSoyList[]> {
    return this.http.get<any>(`${environment.apiUrl}/treatment`, {params})
      .pipe(map((response) => {
        const treatments: TreatmentSoyList[] = [];
        const responseFromJSON = response;
        if (responseFromJSON && responseFromJSON.treatment) {
          responseFromJSON.treatment.forEach((treatment) => {
            const treatmentListItem = this.loadSoyTreatment(treatment, responseFromJSON.treatment_field, plots, fields, responseFromJSON.file, responseFromJSON.treatment_file);
            if (treatmentListItem) {
              treatments.push(treatmentListItem);
            }
          });
        }
        return treatments;
      }));
  }

  getEvaluationType(): EvaluationType[] {
    const evaluationTypeFromJson = JSON.parse(localStorage.getItem('evaluation_type'));
    const evaluationTypes: EvaluationType[] = [];
    if (evaluationTypeFromJson) {
      evaluationTypeFromJson.forEach(item => {
        evaluationTypes.push(EvaluationType.fromJson(item));
      });
    }
    return evaluationTypes;
  }

  getTreatmentType(): TreatmentType[] {
    const treatmentTypesFromJson = JSON.parse(localStorage.getItem('treatment_type'));
    const treatmentTypes: TreatmentType[] = [];
    if (treatmentTypesFromJson) {
      treatmentTypesFromJson.forEach(treatmentType => {
        treatmentTypes.push(TreatmentType.fromJson(treatmentType));
      });
    }
    return treatmentTypes;
  }

  getTreatmentStep(): TreatmentStep[] {
    const treatmentStepsFromJson = JSON.parse(localStorage.getItem('treatment_step'));
    const treatmentSteps: TreatmentStep[] = [];
    if (treatmentStepsFromJson) {
      treatmentStepsFromJson.forEach(treatmentStep => {
        treatmentSteps.push(TreatmentStep.fromJson(treatmentStep));
      });
    }
    return treatmentSteps;
  }

  getTreatmentTypeField(): TreatmentTypeField[] {
    const treatmentTypeFieldsFromJson = JSON.parse(localStorage.getItem('treatment_type_field'));
    const treatmentTypeFields: TreatmentTypeField[] = [];
    if (treatmentTypeFieldsFromJson) {
      treatmentTypeFieldsFromJson.forEach(treatmentTypeField => {
        treatmentTypeFields.push(TreatmentTypeField.fromJson(treatmentTypeField));
      });
    }
    return treatmentTypeFields;
  }

  getPhenologyState(): PhenologyState[] {
    const phenologyStatesFromJson = JSON.parse(localStorage.getItem('phenology_state'));
    const phenologyStates: PhenologyState[] = [];
    if (phenologyStatesFromJson) {
      phenologyStatesFromJson.forEach(phenologyState => {
        phenologyStates.push(PhenologyState.fromJson(phenologyState));
      });
    }
    return phenologyStates;
  }

  getEvaluationTypeById(evaluationTypeId: number): EvaluationType {
    const evaluationTypesFromJson = JSON.parse(localStorage.getItem('evaluation_type'));
    const evaluationType = _.find(evaluationTypesFromJson, {et_id: evaluationTypeId.toString()});
    if (evaluationType) {
      return EvaluationType.fromJson(evaluationType);
    }
    return;
  }

  getTreatmentTypeFieldById(treatmentTypeFieldId: number): TreatmentTypeField {
    const treatmentTypesFieldFromJson = JSON.parse(localStorage.getItem('treatment_type_field'));
    const treatmentTypeField = _.find(treatmentTypesFieldFromJson, {ttf_id: treatmentTypeFieldId.toString()});
    if (treatmentTypeField) {
      return TreatmentTypeField.fromJson(treatmentTypeField);
    }
    return;
  }

  getTreatmentTypeById(treatmentTypeId: number): TreatmentType {
    const treatmentTypesFromJson = JSON.parse(localStorage.getItem('treatment_type'));
    const treatmentType = _.find(treatmentTypesFromJson, {tt_id: treatmentTypeId.toString()});
    if (treatmentType) {
      return TreatmentType.fromJson(treatmentType);
    }
    return;
  }

  getTreatmentFieldById(treatmentFieldId: number): TreatmentField {
    const treatmentFieldFromJson = JSON.parse(localStorage.getItem('treatment_type_field'));
    const treatmentField = _.find(treatmentFieldFromJson, {tt_id: treatmentFieldId.toString()});
    if (treatmentField) {
      return TreatmentField.fromJson(treatmentField);
    }
    return;
  }

  getPhenologyStateById(phenologyId: number): PhenologyState {
    const phenologyStatesFromJson = JSON.parse(localStorage.getItem('phenology_state'));
    const phenologyState = _.find(phenologyStatesFromJson, {ps_id: phenologyId.toString()});
    if (phenologyState) {
      return PhenologyState.fromJson(phenologyState);
    }
    return;
  }

  getIsolationTypeById(isolationId: number): IsolationType {
    const isolationTypesFromJson = JSON.parse(localStorage.getItem('isolation_type'));
    const isolationType = _.find(isolationTypesFromJson, {it_id: isolationId.toString()});
    if (isolationType) {
      return IsolationType.fromJson(isolationType);
    }
    return;
  }

  private getTreatmentFields(treatmentId, treatmentFiedsJson): TreatmentField[] {
    const treatmentFields: TreatmentField[] = [];
    treatmentFiedsJson = _.filter(treatmentFiedsJson, {tf_tr_id: treatmentId.toString()});
    treatmentFiedsJson.forEach(item => {
      const treatmentField = TreatmentField.fromJson(item);
      treatmentField.treatmentTypeField = this.getTreatmentTypeFieldById(item.tf_ttf_id);
      treatmentFields.push(treatmentField);
    });
    return treatmentFields;
  }

  private getGeneratedTreatmentFields(treatmentFieldsJson, treatmentId: number, plot: Plot, treatmentType: number = 1) {
    const treatmentFields = {};
    if (treatmentFieldsJson) {
      const treatmentFieldsFiltered = _.filter(treatmentFieldsJson, {tf_tr_id: treatmentId});
      treatmentFieldsFiltered.forEach(item => {
        if (treatmentFields[item.tf_ttf_id]) {
          if (item.tf_value >= 0) {
            treatmentFields[item.tf_ttf_id].total = parseInt(treatmentFields[item.tf_ttf_id].total) + parseInt(item.tf_value);
            treatmentFields[item.tf_ttf_id].count++;
          }
          treatmentFields[item.tf_ttf_id].data.push({
            pass: item.tf_pass,
            stage: item.tf_stage,
            value: item.tf_value
          });
        } else {
          treatmentFields[item.tf_ttf_id] = {
            treatmentTypeField: this.getTreatmentTypeFieldById(item.tf_ttf_id),
            total: item.tf_value >= 0 ? +item.tf_value : 0,
            count: item.tf_value >= 0 ? 1 : 0,
            percent: 0,
            data: [{
              pass: item.tf_pass,
              stage: item.tf_stage,
              value: item.tf_value
            }]
          };
        }
      });
      if (treatmentType === 1) {
        if (plot.femaleCount > 0 || plot.femaleCount > 0) {
          const keys: string[] = Object.keys(treatmentFields);
          keys.forEach(item => {
            if (treatmentFields[item].total > 0) {
              const plotTrees: number = treatmentFields[item].treatmentTypeField.step === 1 ? plot.femaleCount : plot.maleCount;
              treatmentFields[item].percent = (((treatmentFields[item].total / treatmentFields[item].count / plotTrees)) * 100).toFixed(2);
            }
          });
        }
      } else if (treatmentType === 2) {
        const keys: string[] = Object.keys(treatmentFields);
        keys.forEach(item => {
          if (treatmentFields[item].total > 0) {
            treatmentFields[item].percent = ((treatmentFields[item].total / treatmentFields[item].count)).toFixed(2);
          }
        });
      }
    }
    return treatmentFields;
  }

  private getTreatmentImages(uuid: string, files, treatmentFiles) {
    const images: TreatmentImage[] = [];
    if (treatmentFiles && treatmentFiles.length) {
      treatmentFiles.forEach(treatmentFile => {
        if (treatmentFile.trf_tr_uuid === uuid) {
          images.push(this.getFileById(treatmentFile.trf_file_id, files));
        }
      });
    }
    return images;
  }

  private getFileById(id: string, files): TreatmentImage {
    let image = new TreatmentImage();
    if (files && files.length) {
      files.forEach(file => {
        if (file.file_id === id) {
          image = TreatmentImage.fromJson(file);
        }
      });
    }
    return image;
  }

  private loadCornTreatment(treatmentFromJson, treatmentFieldsJson, plots: Plot[], fields: Field[], files, treatmentFiles): TreatmentList {
    const treatment = new TreatmentList();
    const info = JSON.parse(treatmentFromJson.tr_info);
    treatment.id = treatmentFromJson.tr_id;
    treatment.plot = _.find(plots, {id: +treatmentFromJson.tr_plot_id});
    if (!treatment.plot) {
      return;
    }
    treatment.field = _.find(fields, {id: treatment.plot.fieldId});
    if (!treatment.field) {
      return;
    }
    treatment.uuid = treatmentFromJson.tr_uuid;
    treatment.timestamp = treatmentFromJson.tr_timestamp;
    treatment.hybrid = treatment.plot.hybrid;
    treatment.description = treatmentFromJson.tr_description;
    treatment.evaluationType = this.getEvaluationTypeById(treatmentFromJson.tr_evaluation_type);
    treatment.isolation = this.getIsolationTypeById(+info.isolation_type);
    treatment.cornWind = !!info.corn_wind;
    treatment.phenologyStateFemale = this.getPhenologyStateById(info.pheno_female_state);
    treatment.phenologyStateMale1 = this.getPhenologyStateById(info.pheno_male_1_state);
    treatment.phenologyStateMale2 = this.getPhenologyStateById(info.pheno_male_2_state);
    treatment.phenologyStateMale3 = this.getPhenologyStateById(info.pheno_male_3_state);
    treatment.densityFemale = info.population_female || 0;
    treatment.densityMale1 = info.population_male_1 || 0;
    treatment.densityMale2 = info.population_male_2 || 0;
    treatment.densityMale3 = info.population_male_3 || 0;
    treatment.densityMale3 = info.population_male_3 || 0;
    treatment.fdtMale = info.fdt_male || 0;
    treatment.fdtFemale = info.fdt_female || 0;
    treatment.plantEars = info.plant_ears;
    treatment.user = User.fromJson(treatmentFromJson.user);
    treatment.images = this.getTreatmentImages(treatment.uuid, files, treatmentFiles);
    const generatedTreatmentFields = this.getGeneratedTreatmentFields(treatmentFieldsJson, treatment.id, treatment.plot);
    if (generatedTreatmentFields) {
      const treatmentFieldsKeys: string[] = Object.keys(generatedTreatmentFields);
      treatmentFieldsKeys.forEach(item => {
        switch (generatedTreatmentFields[item].treatmentTypeField.id) {
          case 1:
            treatment.percPanojaWithPollen = TreatmentListFied.fromObject(generatedTreatmentFields[item]);
            break;
          case 2:
            treatment.percPanojaWithoutPollen = TreatmentListFied.fromObject(generatedTreatmentFields[item]);
            break;
          case 3:
            treatment.percPollenMale1 = TreatmentListFied.fromObject(generatedTreatmentFields[item]);
            break;
          case 4:
            treatment.percPollenMale2 = TreatmentListFied.fromObject(generatedTreatmentFields[item]);
            break;
          case 5:
            treatment.percPollenMale3 = TreatmentListFied.fromObject(generatedTreatmentFields[item]);
            break;
          case 6:
            treatment.percMaleFDTWithoutPollen = TreatmentListFied.fromObject(generatedTreatmentFields[item]);
            break;
          case 7:
            treatment.percMaleFDTWithPollen = TreatmentListFied.fromObject(generatedTreatmentFields[item]);
            break;
          case 8:
            treatment.percLatePlant = TreatmentListFied.fromObject(generatedTreatmentFields[item]);
            break;
          case 9:
            treatment.percFemaleFDTWithPollen = TreatmentListFied.fromObject(generatedTreatmentFields[item]);
            break;
          case 10:
            treatment.percFemaleFDTWithoutPollen = TreatmentListFied.fromObject(generatedTreatmentFields[item]);
            break;
          case 11:
            treatment.visibleStigmas = TreatmentListFied.fromObject(generatedTreatmentFields[item]);
            break;
          case 12:
            treatment.aptToHarvest = TreatmentListFied.fromObject(generatedTreatmentFields[item]);
            break;
          case 13:
            treatment.apt = TreatmentListFied.fromObject(generatedTreatmentFields[item]);
            break;
          case 14:
            treatment.dryStigmas = TreatmentListFied.fromObject(generatedTreatmentFields[item]);
            break;
          case 15:
            treatment.percDryMale1 = TreatmentListFied.fromObject(generatedTreatmentFields[item]);
            break;
          case 16:
            treatment.percDryMale2 = TreatmentListFied.fromObject(generatedTreatmentFields[item]);
            break;
          case 17:
            treatment.percDryMale3 = TreatmentListFied.fromObject(generatedTreatmentFields[item]);
            break;
        }
      });
    }
    return treatment;
  }

  private loadSoyTreatment(treatmentFromJson, treatmentFieldsJson, plots: Plot[], fields: Field[], files, treatmentFiles): TreatmentSoyList {
    const treatment = new TreatmentSoyList();
    const info = JSON.parse(treatmentFromJson.tr_info);
    treatment.id = treatmentFromJson.tr_id;
    treatment.plot = _.find(plots, {id: +treatmentFromJson.tr_plot_id});
    if (!treatment.plot) {
      return;
    }
    treatment.field = _.find(fields, {id: treatment.plot.fieldId});
    if (!treatment.field) {
      return;
    }
    treatment.treatmentType = +treatmentFromJson.tr_type;
    treatment.uuid = treatmentFromJson.tr_uuid;
    treatment.timestamp = treatmentFromJson.tr_timestamp;
    treatment.description = treatmentFromJson.tr_description;
    treatment.evaluationType = this.getEvaluationTypeById(treatmentFromJson.tr_evaluation_type);
    treatment.user = User.fromJson(treatmentFromJson.user);
    treatment.images = this.getTreatmentImages(treatment.uuid, files, treatmentFiles);
    const generatedTreatmentFields = this.getGeneratedTreatmentFields(treatmentFieldsJson, treatment.id, treatment.plot, 2);
    if (generatedTreatmentFields) {
      const treatmentFieldsKeys: string[] = Object.keys(generatedTreatmentFields);
      treatmentFieldsKeys.forEach(item => {
        switch (generatedTreatmentFields[item].treatmentTypeField.id) {
          case 18:
            treatment.seedNumber = TreatmentListFied.fromObject(generatedTreatmentFields[item]);
            break;
          case 19:
            treatment.plantingDepth = TreatmentListFied.fromObject(generatedTreatmentFields[item]);
            break;
          case 20:
            treatment.spacing = TreatmentListFied.fromObject(generatedTreatmentFields[item]);
            break;
          case 21:
            treatment.percSproutedPlants = TreatmentListFied.fromObject(generatedTreatmentFields[item]);
            break;
          case 22:
            treatment.aerealPart = TreatmentListFied.fromObject(generatedTreatmentFields[item]);
            break;
          case 23:
            treatment.rootPart = TreatmentListFied.fromObject(generatedTreatmentFields[item]);
            break;
          case 24:
            treatment.nodulesNumber = TreatmentListFied.fromObject(generatedTreatmentFields[item]);
            break;
          case 25:
            treatment.atipicalPlants = TreatmentListFied.fromObject(generatedTreatmentFields[item]);
            break;
          case 26:
            treatment.diseasesNumber = TreatmentListFied.fromObject(generatedTreatmentFields[item]);
            break;
          case 27:
            treatment.pestsNumber = TreatmentListFied.fromObject(generatedTreatmentFields[item]);
            break;
          case 28:
            treatment.humidity = TreatmentListFied.fromObject(generatedTreatmentFields[item]);
            break;
          case 29:
            treatment.mechanicalDamage = TreatmentListFied.fromObject(generatedTreatmentFields[item]);
            break;
          case 30:
            treatment.hypochloriteTest = TreatmentListFied.fromObject(generatedTreatmentFields[item]);
            break;
        }
      });
    }
    return treatment;
  }
}
