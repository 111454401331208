import {Component, ViewEncapsulation} from '@angular/core';
import {AuthService} from './shared/services/auth.service';
import {TranslateService} from '@ngx-translate/core';
import {environment} from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent {

  constructor(public authService: AuthService, private translate: TranslateService) {
    translate.addLangs(['es', 'pt']);
    translate.setDefaultLang('es');
    translate.use(environment.language);
  }
}
