export class Plot {
  id: number;
  name: string;
  fieldId: number;
  hybrid: string;
  maleCount: number;
  femaleCount: number;
  date: Date;
  info: string;
  active: boolean;
  plotTreatmentType: number;

  static fromJson(json): Plot {
    const plot = Object.create(Plot.prototype);
    plot.id = parseInt(json.plot_id);
    plot.name = json.plot_name;
    plot.fieldId = parseInt(json.plot_field_id);
    plot.hybrid = json.plot_hybrid;
    plot.maleCount = json.plot_male_count;
    plot.femaleCount = json.plot_female_count;
    plot.date = json.plot_date;
    plot.info = json.plot_info;
    plot.active = +(json.plot_status) === 1 ? true : false;
    plot.plotTreatmentType = json.plot_treatment_type;
    return plot;
  }

  toJson() {
    return {
      plot_id: this.id,
      plot_name: this.name,
      plot_field_id: this.fieldId,
      plot_hybrid: this.hybrid,
      plot_male_count: this.maleCount,
      plot_female_count: this.femaleCount,
      plot_date: this.date,
      plot_info: this.info,
      plot_status: this.active ? 1 : 0,
      plot_treatment_type: this.plotTreatmentType
    };
  }
}

export class EvolutionaryStage {
  id: number;
  name: string;

  static fromJson(json): EvolutionaryStage {
    const evolutionaryStage = Object.create(EvolutionaryStage.prototype);
    evolutionaryStage.id = +(json.es_id);
    evolutionaryStage.name = json.es_name;
    return evolutionaryStage;
  }

  toJson() {
    return {
      es_id: this.id,
      es_name: this.name,
    };
  }
}

export class AreaType {
  id: number;
  name: string;
  plantNum: string;

  static fromJson(json): AreaType {
    const areaType = Object.create(AreaType.prototype);
    areaType.id = +(json.at_id);
    areaType.name = json.at_name;
    areaType.plantNum = json.at_plant_num;
    return areaType;
  }

  toJson() {
    return {
      at_id: this.id,
      at_name: this.name,
      at_plant_num: this.plantNum,
    };
  }
}

export class PlotSoy {
  id: number;
  name: string;
  fieldId: number;
  info: PlotSoyInfo;
  active: boolean;
  plotTreatmentType: number;

  static fromJson(json): Plot {
    const plot = Object.create(Plot.prototype);
    plot.id = +(json.plot_id);
    plot.name = json.plot_name;
    plot.fieldId = +(json.plot_field_id);
    plot.info = PlotSoyInfo.fromJson(json.plot_info);
    plot.active = +(json.plot_status) === 1 ? true : false;
    plot.plotTreatmentType = json.plot_treatment_type;
    return plot;
  }

  toJson() {
    return {
      plot_id: this.id,
      plot_name: this.name,
      plot_field_id: this.fieldId,
      plot_info: this.info.toJson(),
      plot_status: this.active ? 1 : 0,
      plot_treatment_type: this.plotTreatmentType
    };
  }
}

export class PlotSoyInfo {
  sowingTimeInit: string;
  sowingTimeEnd: string;
  latitude: number;
  longitude: number;
  altitude: number;
  area: number;
  evolutionaryStage: number;
  spaceBetweenPlants: number;
  kind: string;
  areaType: number;

  static fromJson(json): Plot {
    const info = Object.create(PlotSoyInfo.prototype);
    if (json) {
      const jsonObject = JSON.parse(json);
      info.sowingTimeInit = jsonObject.sowing_time_init;
      info.sowingTimeEnd = jsonObject.sowing_time_end;
      info.latitude = parseInt(jsonObject.latitude);
      info.longitude = parseInt(jsonObject.longitude);
      info.altitude = parseInt(jsonObject.altitude);
      info.evolutionaryStage = jsonObject.evolutionary_stage;
      info.spaceBetweenPlants = parseInt(jsonObject.space_between_plants);
      info.kind = jsonObject.kind;
      info.areaType = jsonObject.area_type;
      info.area = parseInt(jsonObject.area);
    }
    return info;
  }

  toJson() {
    const json = {
      space_between_plants: +this.spaceBetweenPlants,
      latitude: this.latitude,
      longitude: this.longitude,
      altitude: this.altitude,
      sowing_time_init: this.sowingTimeInit,
      sowing_time_end: this.sowingTimeEnd,
      evolutionary_stage: this.evolutionaryStage,
      area: this.area,
      kind: this.kind,
      area_type: this.areaType
    };
    return JSON.stringify(json);
  }
}
