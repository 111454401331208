import {User} from './user';

export class Zone {
  id: number;
  name: string;
  active: boolean;
  admin: User;

  static fromJson(json): Zone {
    const zone = Object.create(Zone.prototype);
    zone.id = parseInt(json.zone_id);
    zone.name = json.zone_name;
    zone.active = json.zone_status === 1 ? true : false;
    zone.admin = json.zone_admin;
    return zone;
  }

  toJson() {
    const zone = {
      zone_id: this.id,
      zone_name: this.name,
      zone_status: this.active ? 1 : 0
    };

    if (this.admin) {
      zone['zone_admin'] = this.admin.toJson();
    }

    return zone;
  }
}
