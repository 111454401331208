import {Component, OnInit} from '@angular/core';
import {AuthService} from '../services/auth.service';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  constructor(public authService: AuthService) {
  }

  ngOnInit() {
  }

  isArgentinianEnv() {
    return !environment.brazil;
  }

}
