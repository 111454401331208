import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {AuthService} from './auth.service';

@Injectable()
export class ErrorsService {

  constructor(private router: Router, private authService: AuthService) {
  }

  handleError(error) {
    if (error.status === 401) {
      setTimeout(() => {
        this.authService.clearLocalStorage();
        this.router.navigate(['login']);
      });
    }
  }
}
