import {Component, OnInit} from '@angular/core';
import {AuthService} from '../services/auth.service';
import {Router} from '@angular/router';
import {LoadingService} from '../services/loading.service';
import {ErrorsService} from '../services/errors.service';
import {TreatmentsService} from '../services/treatments.service';
import {TreatmentType} from '../models/treatment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  treatmentTypeList: TreatmentType[] = [];
  isMenuVisible = false;
  showTreatmentTypeSelector: boolean = false;

  constructor(
    public authService: AuthService,
    private treatmentsService: TreatmentsService,
    private router: Router,
    private loadingService: LoadingService,
    private errorsService: ErrorsService) {
  }

  ngOnInit() {
    this.treatmentTypeList = this.treatmentsService.getTreatmentType();
    if (this.treatmentTypeList.length) {
      switch (this.authService.getTreatmentTypeAccess()) {
        case 2:
          // TODO: use the treatment_type_id instead a number fixed
          this.showTreatmentTypeSelector = false;
          this.authService.setTreatmentTypeSelected(1);
          break;
        case 4:
          // TODO: use the treatment_type_id instead a number fixed
          this.showTreatmentTypeSelector = false;
          this.authService.setTreatmentTypeSelected(2);
          break;
        case 6:
          this.showTreatmentTypeSelector = true;
          break;
      }
    }
  }

  logout() {
    this.loadingService.show();
    this.authService.logout().subscribe(() => {
      this.loadingService.hide();
      this.router.navigate(['login']);
    }, error => {
      this.loadingService.hide();
      this.errorsService.handleError(error);
    });
  }

  toggleMenu(): void {
    this.isMenuVisible = !this.isMenuVisible;
  }

  onTreatmentTypeChange(event) {
    this.authService.setTreatmentTypeSelected(+event.target.value);
  }

}
