import { Injectable } from '@angular/core';
import {Role, ROLES} from '../models/role';
import {Observable, of} from 'rxjs';

@Injectable()
export class RolesService {

  constructor() { }

  getRoles(): Observable<Role[]> {
    // TODO: replace this code when we have an endpoint to get the roles
    const role1 = new Role();
    role1.id = 1;
    role1.name = ROLES[1];
    const role2 = new Role();
    role2.id = 2;
    role2.name = ROLES[2];
    const role3 = new Role();
    role3.id = 3;
    role3.name = ROLES[3];
    return of([role1, role2, role3]);
  }
}
