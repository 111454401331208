import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {BreadcrumbComponent} from './breadcrumb/breadcrumb.component';
import {BreadcrumbService} from './services/breadcrumb.service';
import {SearchComponent} from './search/search.component';
import {FilterListPipe} from './pipes/filter-list.pipe';
import {UsersService} from './services/users.service';
import {RolesService} from './services/roles.service';
import {ZonesService} from './services/zones.service';
import {PlotsService} from './services/plots.service';
import {FieldsService} from './services/fields.service';
import {ConfirmComponent} from './dialogs/confirm/confirm.component';
import {TreatmentsService} from './services/treatments.service';
import {LoadingComponent} from './loading/loading.component';
import {BooleanPipe} from './pipes/boolean.pipe';
import {EmptyValuePipe} from './pipes/empty-value.pipe';
import {ImagesComponent} from './dialogs/images/images.component';
import {TranslateModule} from '@ngx-translate/core';
import {ConfirmModule} from './dialogs/confirm/confirm.module';
import {NgbDatepickerModule} from '@ng-bootstrap/ng-bootstrap';
import {PlotsCompareComponent} from './dialogs/plots-compare/plots-compare.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ConfirmModule,
    NgbDatepickerModule,
  ],
  declarations: [
    BreadcrumbComponent,
    SearchComponent,
    FilterListPipe,
    ConfirmComponent,
    ImagesComponent,
    PlotsCompareComponent,
    LoadingComponent,
    BooleanPipe,
    EmptyValuePipe
  ],
  providers: [
    BreadcrumbService,
    UsersService,
    RolesService,
    ZonesService,
    PlotsService,
    FieldsService,
    TreatmentsService
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    BreadcrumbComponent,
    SearchComponent,
    FilterListPipe,
    LoadingComponent,
    BooleanPipe,
    EmptyValuePipe,
    TranslateModule,
    ConfirmModule,
    NgbDatepickerModule
  ],
  entryComponents: [
    ConfirmComponent,
    ImagesComponent,
    PlotsCompareComponent
  ]
})
export class SharedModule {
}
