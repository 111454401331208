import {Component, EventEmitter, Inject, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {Plot} from "../../models/plot";
import {FormControl, FormGroup, Validators} from "@angular/forms";

@Component({
  selector: 'app-images',
  templateUrl: './plots-compare.component.html',
  styleUrls: ['./plots-compare.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PlotsCompareComponent implements OnInit {

  @Input() plots: Plot[] = [];
  @Output() callBackCloseButton = new EventEmitter();
  @Output() callBackCompareButton = new EventEmitter();
  plotCompareForm: FormGroup;

  constructor(public dialogRef: MatDialogRef<PlotsCompareComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.plotCompareForm = new FormGroup({
      plot1: new FormControl('', Validators.required),
      plot2: new FormControl('', Validators.required)
    });
  }

  ngOnInit() {

  }

  onCloseClick(): void {
    this.plotCompareForm.reset();
    this.callBackCloseButton.emit();
  }

  onSubmit() {
    this.callBackCompareButton.emit(this.plotCompareForm.value);
  }
}
