export class Field {
  id: number;
  name: string;
  zoneId: number;
  active: boolean;

  static fromJson(json): Field {
    const field = Object.create(Field.prototype);
    field.id = parseInt(json.field_id);
    field.name = json.field_name;
    field.zoneId = parseInt(json.field_zone_id);
    field.active = json.field_status == 1 ? true : false;
    return field;
  }

  toJson() {
    return {
      field_id: this.id,
      field_name: this.name,
      field_zone_id: this.zoneId,
      field_status: this.active ? 1 : 0
    };
  }
}
