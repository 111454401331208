import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {AppComponent} from './app.component';
import {SharedModule} from './shared/shared.module';
import {AppRoutingModule} from './app-routing.module';
import {HeaderComponent} from './shared/header/header.component';
import {AuthGuardService} from './shared/services/auth.guard.service';
import {AuthService} from './shared/services/auth.service';

import {SidebarComponent} from './shared/sidebar/sidebar.component';
import {NoopAnimationsModule} from '@angular/platform-browser/animations';
import {LoadingService} from './shared/services/loading.service';
import {AdminGuardService} from './shared/services/admin.guard.service';
import {ErrorsService} from './shared/services/errors.service';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {JwtModule} from '@auth0/angular-jwt';
import {JwtInterceptor} from './shared/helpers/jwt.interceptor';
import {ArgentinaGuardService} from "./shared/services/argentina.guard.service";
import {BrazilGuardService} from "./shared/services/brazil.guard.service";

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

export function tokenGetter() {
  return localStorage.getItem('token');
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    SidebarComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    SharedModule,
    AppRoutingModule,
    NoopAnimationsModule,
    HttpClientModule,
    JwtModule.forRoot({
      config: {
        tokenGetter
      }
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    AuthGuardService,
    AdminGuardService,
    AuthService,
    LoadingService,
    ErrorsService,
    ArgentinaGuardService,
    BrazilGuardService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
