import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {User, UserZone} from '../models/user';
import {environment} from '../../../environments/environment';
import {map} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class UsersService {

  constructor(private http: HttpClient) {
  }

  getUsers(): Observable<User[]> {
    return this.http.get<any>(`${environment.apiUrl}/user`)
      .pipe(map((response) => {
        const usersFromJSON = response;
        const users: User[] = [];
        usersFromJSON.forEach((user) => {
          users.push(User.fromJson(user));
        });
        return users.sort((a, b): number => {
          if (a.lastName.toLowerCase() > b.lastName.toLowerCase()) {
            return 1;
          }
          if (a.lastName.toLowerCase() < b.lastName.toLowerCase()) {
            return -1;
          }
          if (a.lastName.toLowerCase() === b.lastName.toLowerCase()) {
            if (a.firstName.toLowerCase() > b.firstName.toLowerCase()) {
              return 1;
            }
            if (a.firstName.toLowerCase() < b.firstName.toLowerCase()) {
              return -1;
            }
            return 0;
          }
        });
      }));
  }

  getUserById(userId: number): Observable<User> {
    return this.http.get<any>(`${environment.apiUrl}/user/${userId}`)
      .pipe(map((response) => {
        return User.fromJson(response);
      }));
  }

  createUser(user: User): Observable<User> {
    return this.http.post<any>(`${environment.apiUrl}/user`, user.toJson())
      .pipe(map(response => {
        return User.fromJson(response);
      }));
  }

  updateUser(user: User): Observable<boolean> {
    return this.http.put(`${environment.apiUrl}/user`, user.toJson())
      .pipe(map(() => {
        return true;
      }));
  }

  getZoneAvailable(userId: number): Observable<UserZone> {
    return this.http.get<any>(`${environment.apiUrl}/user_zone`)
      .pipe(map(response => {
        let userZone: UserZone;
        const zoneAvailablesFromJSON = response;
        if (zoneAvailablesFromJSON) {
          zoneAvailablesFromJSON.forEach((uz) => {
            if (+uz.uz_user_id === userId) {
              userZone = UserZone.fromJson(uz);
            }
          });
        }
        return userZone;
      }));
  }

  addZoneAvailable(userZone: UserZone): Observable<boolean> {
    return this.http.post<any>(`${environment.apiUrl}/user_zone`, userZone.toJson())
      .pipe(map(() => {
        return true;
      }));
  }

  removeZoneAvailable(userZoneId: number): Observable<boolean> {
    return this.http.delete(`${environment.apiUrl}/user_zone/${userZoneId}`)
      .pipe(map(() => {
        return true;
      }));
  }

  deleteUser(userId: number): Observable<boolean> {
    return this.http.delete(`${environment.apiUrl}/user/${userId}`)
      .pipe(map(() => {
        return true;
      }));
  }
}
