import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot} from '@angular/router';
import {environment} from '../../../environments/environment';

@Injectable()
export class ArgentinaGuardService implements CanActivate {

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return !environment.brazil;
  }
}
