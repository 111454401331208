import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'emptyValue'
})
export class EmptyValuePipe implements PipeTransform {

  transform(item: any, args?: any): any {
    if (!item || item.value == -1) {
      return '-';
    }
    return item.value;
  }

}
