import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Zone} from '../models/zone';
import {environment} from '../../../environments/environment';
import {HttpClient, HttpParams} from '@angular/common/http';
import {map} from 'rxjs/operators';

@Injectable()
export class ZonesService {

  constructor(private http: HttpClient) {
  }

  getAllZones(): Observable<Zone[]> {
    let params: HttpParams = new HttpParams();
    params = params.set('all', '1');
    return this.loadZones(params);
  }

  getZones(): Observable<Zone[]> {
    return this.loadZones();
  }

  private loadZones(params?: HttpParams): Observable<Zone[]> {
    return this.http.get<any>(`${environment.apiUrl}/zone`, {params})
      .pipe(map((response) => {
        const zonesFromJSON = response;
        const zones: Zone[] = [];
        zonesFromJSON.forEach((zone) => {
          zones.push(Zone.fromJson(zone));
        });
        return zones.sort((a, b): number => {
          if (a.name.toLowerCase() > b.name.toLowerCase()) {
            return 1;
          }
          if (a.name.toLowerCase() < b.name.toLowerCase()) {
            return -1;
          }
          return 0;
        });
      }));
  }

  getZoneById(zoneId: number): Observable<Zone> {
    return this.http.get<any>(`${environment.apiUrl}/zone/${zoneId}`)
      .pipe(map((response) => {
        return Zone.fromJson(response);
      }));
  }

  createZone(zone: Zone): Observable<boolean> {
    return this.http.post<any>(`${environment.apiUrl}/zone`, zone.toJson())
      .pipe(map(() => {
        return true;
      }));
  }

  updateZone(zone: Zone): Observable<boolean> {
    return this.http.put<any>(`${environment.apiUrl}/zone`, zone.toJson())
      .pipe(map(() => {
        return true;
      }));
  }

  deleteZone(zoneId: number): Observable<boolean> {
    return this.http.delete(`${environment.apiUrl}/zone/${zoneId}`)
      .pipe(map(() => {
        return true;
      }));
  }
}
