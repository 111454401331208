import { Injectable } from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';

@Injectable()
export class LoadingService {

  private _isLoading: Subject<boolean> = new BehaviorSubject<boolean>(false);

  public isLoading = this._isLoading.asObservable();

  constructor() { }

  show() {
    this._isLoading.next(true);
  }

  hide() {
    this._isLoading.next(false);
  }
}
