import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'filter',
  pure: false
})
export class FilterListPipe implements PipeTransform {

  transform(items: any, term: any, fields: string[]): any {
    if (term === undefined || !fields) { return items; }

    let filters = '';
    fields.forEach(field => {
      if (filters) {
        filters += ' || ';
      }
      filters += `item.${field}.toString().toLowerCase().includes(term.toLowerCase())`;
    });

    return items.filter(function(item) {
      return eval(filters);
    });
  }

}


