import {Component, EventEmitter, Inject, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import * as $ from 'jquery';

@Component({
  selector: 'app-images',
  templateUrl: './images.component.html',
  styleUrls: ['./images.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ImagesComponent implements OnInit {

  @Output() callBackCloseButton = new EventEmitter();
  slickCarousel;

  constructor(public dialogRef: MatDialogRef<ImagesComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {

  }

  ngOnInit() {
    setTimeout(() => {
      this.slickCarousel = ($('.images') as any).slick({
        dots: true,
        infinite: true,
        speed: 300,
        slidesToShow: 1
      });
    });
  }

  onCloseClick(): void {
    this.callBackCloseButton.emit();
  }

  slickPrev() {
    this.slickCarousel.slickPrev();
  }

  slickNext() {
    this.slickCarousel.slickNext();
  }
}
