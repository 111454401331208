import {Component, EventEmitter, Inject, OnInit, Output} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss']
})
export class ConfirmComponent implements OnInit {

  @Output() callBackNoButton = new EventEmitter();
  @Output() callBackYesButton = new EventEmitter();

  constructor(public dialogRef: MatDialogRef<ConfirmComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {

  }

  ngOnInit() {
  }

  onNoClick(): void {
    this.callBackNoButton.emit();
  }

  onYesClick(): void {
    this.callBackYesButton.emit();
  }

}
