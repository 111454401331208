import {Injectable} from '@angular/core';
import {AreaType, EvolutionaryStage, Plot, PlotSoy} from '../models/plot';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import * as _ from 'lodash';
import {HttpClient, HttpParams} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {AuthService} from "./auth.service";

@Injectable()
export class PlotsService {

  constructor(private http: HttpClient, private authService: AuthService) {
  }

  getAllPlots(fieldId?: number[]): Observable<any[]> {
    let params: HttpParams = new HttpParams();
    params = params.set('all', '1');
    return this.loadPlots(params, fieldId);
  }

  getPlots(fieldId?: number[]): Observable<any[]> {
    return this.loadPlots(null, fieldId);
  }

  getEvolutionaryStage(): Observable<EvolutionaryStage[]> {
    return this.http.get<any>(`${environment.apiUrl}/evolutionary_stage`)
      .pipe(map((response) => {
        const evolutionaryStageFromJSON = response;
        const evolutionaryStages: EvolutionaryStage[] = [];
        evolutionaryStageFromJSON.forEach((evolutionaryStage) => {
          evolutionaryStages.push(EvolutionaryStage.fromJson(evolutionaryStage));
        });
        return evolutionaryStages;
      }));
  }

  getAreaType(): Observable<AreaType[]> {
    return this.http.get<any>(`${environment.apiUrl}/area_type`)
      .pipe(map((response) => {
        const areaTypesFromJSON = response;
        const areaTypes: AreaType[] = [];
        areaTypesFromJSON.forEach((areaType) => {
          areaTypes.push(AreaType.fromJson(areaType));
        });
        return areaTypes;
      }));
  }

  getPlotById(plotId: number): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/plot/${plotId}`)
      .pipe(map((response) => {
        if (+response.plot_treatment_type === 1) {
          return Plot.fromJson(response);
        }
        return PlotSoy.fromJson(response);
      }));
  }

  createPlot(plot: any): Observable<boolean> {
    return this.http.post<any>(`${environment.apiUrl}/plot`, plot.toJson())
      .pipe(map(() => {
        return true;
      }));
  }

  updatePlot(plot: any): Observable<boolean> {
    return this.http.put<any>(`${environment.apiUrl}/plot`, plot.toJson())
      .pipe(map(() => {
        return true;
      }));
  }

  createPlotSoy(plot: PlotSoy): Observable<boolean> {
    return this.http.post<any>(`${environment.apiUrl}/plot`, plot.toJson())
      .pipe(map(() => {
        return true;
      }));
  }

  updatePlotSoy(plot: PlotSoy): Observable<boolean> {
    return this.http.put<any>(`${environment.apiUrl}/plot`, plot.toJson())
      .pipe(map(() => {
        return true;
      }));
  }

  deletePlot(plotId: number): Observable<boolean> {
    return this.http.delete(`${environment.apiUrl}/plot/${plotId}`)
      .pipe(map(() => {
        return true;
      }));
  }

  private loadPlots(params?: HttpParams, fieldId?: number[]): Observable<any[]> {
    return this.http.get<any>(`${environment.apiUrl}/plot`, {params})
      .pipe(map((response) => {
        const plotsFromJSON = response;
        if (this.authService.getTreatmentTypeSelectedValue() === 1) {
          const plots: Plot[] = [];
          plotsFromJSON.forEach((plot) => {
            if (+(plot.plot_treatment_type) === this.authService.getTreatmentTypeSelectedValue() &&
              ((fieldId && _.includes(fieldId, +plot.plot_field_id)) || !fieldId)) {
              plots.push(Plot.fromJson(plot));
            }
          });
          return plots.sort((a, b): number => {
            if (a.name.toLowerCase() > b.name.toLowerCase()) {
              return 1;
            }
            if (a.name.toLowerCase() < b.name.toLowerCase()) {
              return -1;
            }
            return 0;
          });
        } else {
          const plotSoyList: PlotSoy[] = [];
          plotsFromJSON.forEach((plotSoy) => {
            if (+(plotSoy.plot_treatment_type) === this.authService.getTreatmentTypeSelectedValue() &&
              ((fieldId && _.includes(fieldId, +plotSoy.plot_field_id)) || !fieldId)) {
              // @ts-ignore
              plotSoyList.push(PlotSoy.fromJson(plotSoy));
            }
          });
          return plotSoyList.sort((a, b): number => {
            if (a.name.toLowerCase() > b.name.toLowerCase()) {
              return 1;
            }
            if (a.name.toLowerCase() < b.name.toLowerCase()) {
              return -1;
            }
            return 0;
          });
        }
      }));
  }
}
