import {Field} from './field';
import {Plot, PlotSoyInfo} from './plot';
import {EvaluationType, IsolationType, PhenologyState, TreatmentImage, TreatmentTypeField} from './treatment';
import {User} from './user';

export class FieldData {
  pass: number;
  stage: number;
  value: number;

  static fromObject(treatmentListFied): FieldData[] {
    const fieldsData: FieldData[] = [];
    if (treatmentListFied.data && treatmentListFied.data.length) {
      treatmentListFied.data.forEach(item => {
        const fieldData: FieldData = new FieldData();
        fieldData.pass = item.pass;
        fieldData.stage = item.stage;
        fieldData.value = item.value;
        fieldsData.push(fieldData);
      });
    }
    return fieldsData;
  }
}

export class TreatmentListFied {
  treatmentTypeField: TreatmentTypeField;
  total: number;
  count: number;
  percent: number;
  data: FieldData[];

  constructor() {
    this.total = 0;
    this.count = 0;
    this.percent = 0;
    this.data = [];
  }

  static fromObject(item): TreatmentListFied {
    const treatmentListField: TreatmentListFied = new TreatmentListFied();
    treatmentListField.treatmentTypeField = item.treatmentTypeField;
    treatmentListField.total = item.total;
    treatmentListField.count = item.count;
    treatmentListField.percent = item.percent;
    treatmentListField.data = FieldData.fromObject(item);
    return treatmentListField;
  }
}

export class TreatmentList {
  id: number;
  field: Field;
  plot: Plot;
  hybrid: string;
  evaluationType: EvaluationType;
  timestamp: Date;
  description: string;
  isolation: IsolationType;
  cornWind: boolean;
  uuid: string;
  phenologyStateFemale: PhenologyState;
  phenologyStateMale1: PhenologyState;
  phenologyStateMale2: PhenologyState;
  phenologyStateMale3: PhenologyState;
  percPanojaWithPollen: TreatmentListFied;
  percPanojaWithoutPollen: TreatmentListFied;
  percLatePlant: TreatmentListFied;
  percFemaleFDTWithoutPollen: TreatmentListFied;
  percFemaleFDTWithPollen: TreatmentListFied;
  percMaleFDTWithoutPollen: TreatmentListFied;
  percMaleFDTWithPollen: TreatmentListFied;
  percPollenMale1: TreatmentListFied;
  percPollenMale2: TreatmentListFied;
  percPollenMale3: TreatmentListFied;
  percDryMale1: TreatmentListFied;
  percDryMale2: TreatmentListFied;
  percDryMale3: TreatmentListFied;
  visibleStigmas: TreatmentListFied;
  dryStigmas: TreatmentListFied;
  densityFemale: TreatmentListFied;
  densityMale1: number;
  densityMale2: number;
  densityMale3: number;
  user: User;
  aptToHarvest: TreatmentListFied;
  apt: TreatmentListFied;
  fdtMale: number;
  fdtFemale: number;
  plantEars: string;
  images: TreatmentImage[];

  constructor() {
    this.percPanojaWithPollen = new TreatmentListFied();
    this.percPanojaWithoutPollen = new TreatmentListFied();
    this.percLatePlant = new TreatmentListFied();
    this.percFemaleFDTWithoutPollen = new TreatmentListFied();
    this.percFemaleFDTWithPollen = new TreatmentListFied();
    this.percMaleFDTWithoutPollen = new TreatmentListFied();
    this.percMaleFDTWithPollen = new TreatmentListFied();
    this.percPollenMale1 = new TreatmentListFied();
    this.percPollenMale2 = new TreatmentListFied();
    this.percPollenMale3 = new TreatmentListFied();
    this.dryStigmas = new TreatmentListFied();
    this.visibleStigmas = new TreatmentListFied();
    this.densityMale1 = 0;
    this.densityMale2 = 0;
    this.densityMale3 = 0;
    this.aptToHarvest = new TreatmentListFied();
    this.apt = new TreatmentListFied();
  }
}

export class TreatmentSoyList {
  id: number;
  info: PlotSoyInfo;
  field: Field;
  plot: Plot;
  evaluationType: EvaluationType;
  timestamp: Date;
  description: string;
  uuid: string;
  treatmentType: number;
  seedNumber: TreatmentListFied;
  plantingDepth: TreatmentListFied;
  spacing: TreatmentListFied;
  percSproutedPlants: TreatmentListFied;
  aerealPart: TreatmentListFied;
  rootPart: TreatmentListFied;
  nodulesNumber: TreatmentListFied;
  atipicalPlants: TreatmentListFied;
  diseasesNumber: TreatmentListFied;
  pestsNumber: TreatmentListFied;
  humidity: TreatmentListFied;
  mechanicalDamage: TreatmentListFied;
  hypochloriteTest: TreatmentListFied;
  user: User;
  images: TreatmentImage[];

  constructor() {
    this.plantingDepth = new TreatmentListFied();
    this.spacing = new TreatmentListFied();
    this.percSproutedPlants = new TreatmentListFied();
    this.aerealPart = new TreatmentListFied();
    this.rootPart = new TreatmentListFied();
    this.nodulesNumber = new TreatmentListFied();
    this.atipicalPlants = new TreatmentListFied();
    this.diseasesNumber = new TreatmentListFied();
    this.pestsNumber = new TreatmentListFied();
    this.humidity = new TreatmentListFied();
    this.mechanicalDamage = new TreatmentListFied();
    this.hypochloriteTest = new TreatmentListFied();
  }
}
