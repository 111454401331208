import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';

export const routes: Routes = [
  {path: '', redirectTo: '/treatments', pathMatch: 'full'},
  {path: 'login', loadChildren: './login/login.module#LoginModule'},
  {path: 'zones', loadChildren: './zones/zones.module#ZonesModule'},
  {path: 'users', loadChildren: './users/users.module#UsersModule'},
  {path: 'fields', loadChildren: './fields/fields.module#FieldsModule'},
  {path: 'plots', loadChildren: './plots/plots.module#PlotsModule'},
  {path: 'treatments', loadChildren: './treatments/treatments.module#TreatmentsModule'},
  {path: 'alerts', loadChildren: './alerts/alerts.module#AlertsModule'},
  {path: 'charts', loadChildren: './charts/charts.module#ChartsModule'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
