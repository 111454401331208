export const ROLES = {
  1: 'Administrador',
  2: 'Coordinador',
  3: 'Zonales'
};

export class Role {
  id: number;
  name: string;

  static fromId(id): Role {
    const role = Object.create(Role.prototype);
    role.id = id;
    role.name = ROLES[id];
    return role;
  }

  toJson() {
    return {
      ur_name: this.name
    };
  }
}
